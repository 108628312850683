export const metricsExplained: {[key:string]:string} = {
  res: 'The combined score of all the Web Vitals experienced by visitors.',
  fcp: 'First Contentful Paint (FCP) is an important, user-centric metric for measuring perceived load speed because it marks the first point in the page load timeline where the user can see anything on the screen—a fast FCP helps reassure the user that something is happening.',
  cls: 'Cumulative Layout Shift (CLS) is an important, user-centric metric for measuring visual stability because it helps quantify how often users experience unexpected layout shifts—a low CLS helps ensure that the page is delightful.',
  fid: 'First Input Delay (FID) is an important, user-centric metric for measuring load responsiveness because it quantifies the experience users feel when trying to interact with unresponsive pages—a low FID helps ensure that the page is usable.',
  lcp: `Largest Contentful Paint (LCP) is an important, user-centric metric for measuring perceived load speed because it marks the point in the page load timeline when the page's main content has likely loaded—a fast LCP helps reassure the user that the page is useful.`
}

export const metricNames: {[key:string]:string} = {
  res: "Real Experience Score",
  fcp:"First Contentful Paint",
  lcp:"Largest Contentful Paint",
  cls:"Cumulative Layout Shift",
  fid:"First Input Delay"
}

export const chartOptions = {
  autocolors: true,
  responsive: true,
  AspectRatio: 2,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    annotation:{
      annotations:{} as { 
        [key:string] : {
          [key:string]:string | number | number[]
        }
      }
    }
  }
}

export const urlNames: {[key:string]:string} = {
  'home': 'https://www.sonos.com/en-us/home',
  'shop/wireless-speakers': 'https://www.sonos.com/en-us/shop/wireless-speakers',
  'shop/roam': 'https://www.sonos.com/en-us/shop/roam',
  'shop/sub-mini': 'https://www.sonos.com/en-us/shop/sub-mini',
  'shop/move':'https://www.sonos.com/en-us/shop/move'
}

export const auditProperties = ['performance','best-practices','accessibility','seo']

export const chartInitStateVercel = {labels:[''], datasets: [{
  metric: '',
  label: '',
  data: [0],
  borderColor: '',
  backgroundColor: ''
}]}

export const chartInitStateLH = {labels:[''], datasets: [{
  metric: '',
  label: '',
  data: [0],
  borderColor: '',
  backgroundColor: '',
  url:''
}]}

export const availableDurations = {
  7: 'Last Week',
  30: 'Last Month',
  90: 'Last 3 Months'
}