import { useEffect, useState } from 'react'

import './App.css'

import Lighthouse from './components/lighthouse'
import Vercel from './components/vercel'
import {  Card, Tab, Tabs, TabList, TabPanel, Box,  Typography, IconButton, Button, Sheet } from '@mui/joy'
import { CssVarsProvider, extendTheme, useColorScheme } from '@mui/joy/styles'
import EqualizerRoundedIcon from '@mui/icons-material/EqualizerRounded'
import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material'
import { SxProps } from '@mui/system'
function App() {
  const [index, setIndex] = useState(0)
  const [node, setNode] = useState(null as HTMLElement | null)
  useEffect(() => {
    setNode(document.getElementById('app'))
  }, []);

  const fluentTheme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            solidBg: '#000000',
            solidHoverBg: '#2f2f2f',
            solidActiveBg: '#3b3b3b',
            solidDisabledBg: '#616161',
            solidDisabledColor: '#9f9f9f',
          },
        }
      },
      dark: {
        palette: {
          primary: {
            solidColor: '#000000',
            solidBg: '#ffffff',
            solidHoverBg: '#e9e9e9',
            solidActiveBg: '#dddddd',
            solidDisabledBg: '#757575',
            solidDisabledColor: '#919191',
          },
        }
      }
    }
  })

  return (
    <CssVarsProvider
      defaultMode="system"
      colorSchemeSelector="#app"
      colorSchemeNode={node || null}
      modeStorageKey="app"
      //disableNestedContext
      theme={fluentTheme}
    >
      <Sheet sx={{m:0, height:'99.99vh', pt:'1px'}} variant='soft'>
      <Card variant='plain' sx={{p:{xs:1, md:2}, m:2, boxShadow:'lg'}}>
          <Tabs size="lg" value={index} onChange={(event, value) => setIndex(value as number)}>
          <Card sx={{display: {sm:'block', md:'grid'}, gridTemplateColumns: 'repeat(8, 1fr)', gap: 1, boxShadow:'none', p:'5px'}}
            variant='plain'>
            <IconButton
              size="lg"
              variant="plain"
              color='neutral'
              sx={{ pointerEvents: 'none', gridRow: '1', gridColumn: '1/3', justifyContent: 'flex-start' }}
            >
              <EqualizerRoundedIcon />
              <Typography level='h3' sx={{p:2, pl:0}}>Web Metrics</Typography>
              
            </IconButton>
            <Box sx={{gridRow: '1', gridColumn: '4/6', display:'flex'}}>
              <TabList variant="plain" color="neutral" sx={{width:1}}>
                <Tab 
                  variant={index === 0 ? 'solid' : 'plain'}
                  color={index === 0 ? 'primary' : 'neutral'}
                >Vercel</Tab>
                <Tab 
                  variant={index === 1 ? 'solid' : 'plain'}
                  color={index === 1 ? 'primary' : 'neutral'}
                >Lighthouse</Tab>
              </TabList>
              <Box sx={{display: {xs:'inherit',sm:'inherit',md:'none', alignItems:'center'}}}>
                <ModeSwitcher sx={{height: '40px', width:'40px'}} />
              </Box>
            </Box>
            <Box sx={{gridRow: '1', gridColumn: '8/9', width:'100%', display:'flex', justifyContent: 'flex-end', alignItems:'center'}}>
            <Box sx={{height: '40px', width:'40px', mr:2,
                display: {xs:'none',sm:'none',md:'inherit'}
              }}>
              <ModeSwitcher />
            </Box>
            </Box>
          </Card>
          
          <TabPanel value={0}>
            <Vercel />
          </TabPanel>
          <TabPanel value={1}>
            <Lighthouse />
          </TabPanel>
        </Tabs>
      </Card>
      </Sheet>
    </CssVarsProvider>
  )
}

function ModeSwitcher(sx: SxProps) {
  const { mode, setMode, systemMode } = useColorScheme()

  const switchMode = function(){
    if(mode === 'system'){
      systemMode === 'light' ? setMode('dark') : setMode('light')
    } else if (mode === 'light') setMode('dark')
    else setMode('light')
  }

  return (
    <Button sx={sx} onClick={switchMode}>
        {mode === 'system' ? (systemMode === 'light' ? <DarkModeOutlined/> : <LightModeOutlined/>) : <></>}
        {mode === 'light' ? <DarkModeOutlined/> : <></>}
        {mode === 'dark' ? <LightModeOutlined/> : <></>}
    </Button>
  )
}

export default App
