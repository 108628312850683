import { Card, CircularProgress, FormControl, FormLabel, Radio, RadioGroup, Select, Sheet, Option, ListItem, Checkbox, List, Typography, Alert } from '@mui/joy'
import { useEffect, useState } from 'react'
import mock from '../mock.json'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { auditProperties, availableDurations, chartInitStateLH, chartOptions, metricsExplained, urlNames } from '../data/constants'

function Lighthouse() {
  const [isLoading, setIsLoading] = useState(false)
  const [ap, setAp] = useState(auditProperties[0])
  const [data, setData] = useState(chartInitStateLH)
  const [duration, setDuration] = useState('30')
  const [checked, setChecked] = useState(Object.values(urlNames))
  
  const [allData, setAllData] = useState(data)
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  const [options, setOptions] = useState({})

  const fetchData = async (limit: string) => {
    setIsLoading(true)
    let res = await fetch(`/api/get?sonosauth=true&url=${ encodeURIComponent(`https://metrics.nextweb.sonos.com/api/lhci-metrics?limit=${limit}`) }`)
    let result = [mock.lighthouse]
    result = await res.json()
    result = result.sort((a,b)=>{return a.createdAt<b.createdAt?-1:1})
    const labels = result.map(m => new Date(m.stringDate).toLocaleDateString('en-US'))
    const lineColors: {[key:string]:string} = {
      'https://www.sonos.com/en-us/home':'#5E565A',
      'https://www.sonos.com/en-us/shop/wireless-speakers':'#a9cbb7',
      'https://www.sonos.com/en-us/shop/roam':'#2596be',
      'https://www.sonos.com/en-us/shop/sub-mini':'#ff934f',
      'https://www.sonos.com/en-us/shop/move':'#ABDF75',
    }

    let datasets = chartInitStateLH.datasets
    let dataMap = new Map()
    result.forEach(r => {
      r.data.forEach(d => {
        if(dataMap.has(d.url+d.auditProperty)){
          const prevData = dataMap.get(d.url+d.auditProperty)
          prevData.data.push(d.actual*100)
          dataMap.set(d.url+d.auditProperty, prevData)
        }else{
          dataMap.set(d.url+d.auditProperty, {
            metric: d.auditProperty,
            url: d.url,
            label: Object.keys(urlNames).find(key => urlNames[key] === d.url),
            data: [d.actual*100],
            borderColor: lineColors[d.url],
            backgroundColor: lineColors[d.url],
          })
        }
      })
    })

    datasets = Array.from(dataMap.values())

    const setup =  {
      labels,
      datasets,
    }

    setAllData(setup)
    const filtered = {
      labels: setup.labels,
      datasets: setup.datasets.filter(s => s.metric === ap && checked.includes(s.url))
    }
    setData(filtered)
    const deploymentsLimitDate = new Date().setDate(new Date().getDate()-(parseInt(limit)))
    let deployments = mock.vercelDeployments
    res = await fetch(`/api/get?vercelauth=true&url=${ encodeURIComponent(`https://vercel.com/api/v6/deployments?projectId=prj_geVQ6rMbFVBHsc1BRsuVx97choo1&teamId=team_grru4Jusir6dBzUqAkDScxYD&limit=500&target=production&since=${deploymentsLimitDate}`) }`)
    deployments = await res.json()
    const succeeded = deployments.deployments.filter(d => d.checksConclusion === 'succeeded')
    const labelledOptions = JSON.parse(JSON.stringify(chartOptions))
    labelledOptions.scales = {y : {
      min:0,
      max:100
    }}
    succeeded.forEach(s => {
      const date = new Date(s.createdAt).toLocaleDateString('en-US')
      const annotation = {
        type: 'line',
        borderWidth: 1.5,
        borderColor: 'grey',
        borderDash: [10,10],
        label: {
          display: (ctx: any) => ctx.hovered,
          backgroundColor: 'gray',
          content: (ctx: any) => [s.meta.githubCommitMessage,'Branch: '+ s.meta.githubCommitRef, 'Deployed by: '+ s.creator.username],
          textAlign: 'start'
        },
        scaleID: 'x',
        value: date,
        enter(ctx: any) {
          ctx.hovered = true;
          ctx.chart.update();
        },
        leave(ctx: any) {
          ctx.hovered = false;
          ctx.chart.update();
        }
      }
      if(labels.includes(date)) {
        labelledOptions.plugins.annotation.annotations[s.createdAt] = annotation
      }
      
    })
    setOptions(labelledOptions)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData(duration)
    .then(() => {
      setIsLoading(false)
    })
    .catch(e => {
      setIsLoading(false)
      console.log(e)
    })
  }, []);

  const handleMetricChange = function (e: { target: {value: string} }) {
    const val = e?.target?.value
    setAp(val)
    setData({
      labels: allData.labels,
      datasets: allData.datasets.filter(a => a.metric === val)
    })
  }

  const handleUrlFilter = function (e: { target: {value: string, checked: boolean}, }) {
    const val = e?.target?.value
    let checkedCopy = JSON.parse(JSON.stringify(checked))
    if(e.target.checked && !checkedCopy.includes(val)) checkedCopy.push(val)
    else checkedCopy = checkedCopy.filter( (e: string) => e !== val)
    console.log(checkedCopy)
    setChecked(checkedCopy)
    setData({
      labels: allData.labels,
      datasets: allData.datasets.filter(a => a.metric === ap && checkedCopy.includes(a.url))
    })
  }

  const handleDurationChange = function (e: unknown, newValue: unknown) {
    const val = newValue as string
    setDuration(val)
    fetchData(val)
  }
  
  return (
      <Sheet sx={{display: {md:'block', lg:'grid'}, gridTemplateColumns: {md:'repeat(16, 1fr)'}, gap: 1}}>
        <Card variant='outlined' sx={{gridRow: '1', gridColumn: '1/4', boxShadow: 'md', mb:1}}>
          <FormControl>
            <FormLabel>Metric:</FormLabel>
            <RadioGroup value={ap} onChange={handleMetricChange} sx={{mt:1, mb:3}}>
              {auditProperties.map((a) => <Radio color='neutral' value={a} label={a} key={a}></Radio>)}
            </RadioGroup>
          </FormControl>
          {ap==='performance'?<Alert color="neutral" variant="outlined" sx={{ display:'block', mb:4, boxShadow:'md'}}>
              <Typography color='neutral' level="h6" fontSize="md" fontWeight="lg" sx={{mb:1}}>Performace</Typography>
              <Typography fontSize="sm" sx={{mb:2}}>{metricsExplained.res}</Typography>
              <img src={'res.png'} alt='red' style={{maxWidth:'100%', borderRadius:'5px'}}></img>
          </Alert>:<></>}
          <FormLabel>Duration:</FormLabel>
          <FormControl>
            <Select value={duration} onChange={handleDurationChange} sx={{mt:1}} color='neutral'>
              {Object.entries(availableDurations).map(([k,v])=><Option value={k} key={k}>{v}</Option>)}
            </Select>
          </FormControl>
          <FormLabel sx={{mt:3}}>Urls:</FormLabel>
          <List size="sm">
            {
              Object.entries(urlNames).map(([k,v]) => 
              <FormControl key={k}>
                <ListItem>
                  <Checkbox label={k} value={v} checked={checked.includes(v)} onChange={handleUrlFilter}/>
                </ListItem>
              </FormControl>
              )
            }
          </List>
        </Card>
        <Card variant='outlined' sx={{ gridRow: '1', gridColumn: '4/17', boxShadow: 'md', mb:1 }}>
          {
            isLoading 
            ?
            <div style={{textAlign: 'center', margin: '200px'}}>
              <CircularProgress color="neutral" variant="soft"/>
            </div>
            : <>
                <Typography sx={{ textAlign: 'center', display:'flex', margin:'auto', color:'#717171', fontSize:'13px'}}> 
                  <div style={{width:'15px', height:'15px',borderRadius:'15px', borderStyle:'dashed', borderColor:'grey', borderWidth:'2px', marginRight:'5px'}}></div>
                  Deployments
                </Typography>
                <Line id="lighthouse-chart" options={options} data={data} />
              </>
          }
        </Card>
      </Sheet>
  )
}

export default Lighthouse