import { Card, CircularProgress, FormControl, FormLabel, Radio, RadioGroup, Select, Sheet, Option, Typography, Alert } from '@mui/joy'
import React, { useEffect, useState } from 'react'
import mock from '../mock.json'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import AnnotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2'
import { availableDurations, chartInitStateVercel, chartOptions, metricNames, metricsExplained } from '../data/constants'

function Vercel() {
  const [isLoading, setIsLoading] = useState(false)
  const [metric, setMetric] = useState('res')
  const [data, setData] = useState(chartInitStateVercel)
  const [duration, setDuration] = useState('30')
  
  const [allData, setAllData] = useState(data)
  const [title, setTitle] = useState(metricNames.res)
  const [explained, setExplained] = useState(metricsExplained.res)
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    AnnotationPlugin
  )
  const [options, setOptions] = useState({})

  const fetchData = async (limit: string) => {
    setIsLoading(true)
    let res = await fetch(`/api/get?sonosauth=true&url=${ encodeURIComponent(`https://metrics.nextweb.sonos.com/api/vercel-metrics?limit=${limit}`) }`)
    let result = [mock.vercel]
    result = await res.json()
    result = result.sort((a,b)=>{return a.createdAt<b.createdAt?-1:1})
    const labels = result.map(m => new Date(m.stringDate).toLocaleDateString('en-US'))
    const lineColors = {
      mobile: '#1e81b0',
      tablet: '#e28743',
      desktop: '#ABDF75'
    }
    const setup =  {
      labels,
      datasets: [
        {
          metric: 'res',
          device: 'desktop',
          label: 'Desktop',
          data: result.map(m => m.data.devices.desktop.p_scores[0]),
          borderColor: lineColors.desktop,
          backgroundColor: lineColors.desktop,
        },
        {
          metric: 'fcp',
          device: 'desktop',
          label: 'Desktop',
          data: result.map(m => m.data.devices.desktop.vitals.FCP.p_values[0]/1000),
          borderColor: lineColors.desktop,
          backgroundColor: lineColors.desktop,
        },
        {
          metric: 'lcp',
          device: 'desktop',
          label: 'Desktop',
          data: result.map(m => m.data.devices.desktop.vitals.LCP.p_values[0]/1000),
          borderColor: lineColors.desktop,
          backgroundColor: lineColors.desktop,
        },
        {
          metric: 'cls',
          device: 'desktop',
          label: 'Desktop',
          data: result.map(m => m.data.devices.desktop.vitals.CLS.p_values[0]),
          borderColor: lineColors.desktop,
          backgroundColor: lineColors.desktop,
        },
        {
          metric: 'fid',
          device: 'desktop',
          label: 'Desktop',
          data: result.map(m => m.data.devices.desktop.vitals.FID.p_values[0]),
          borderColor: lineColors.desktop,
          backgroundColor: lineColors.desktop,
        },
        {
          metric: 'res',
          device: 'mobile',
          label: 'Mobile',
          data: result.map(m => m.data.devices.mobile.p_scores[0]),
          borderColor: lineColors.mobile,
          backgroundColor: lineColors.mobile,
        },
        {
          metric: 'fcp',
          device: 'mobile',
          label: 'Mobile',
          data: result.map(m => m.data.devices.mobile.vitals.FCP.p_values[0]/1000),
          borderColor: lineColors.mobile,
          backgroundColor: lineColors.mobile,
        },
        {
          metric: 'lcp',
          device: 'mobile',
          label: 'Mobile',
          data: result.map(m => m.data.devices.mobile.vitals.LCP.p_values[0]/1000),
          borderColor: lineColors.mobile,
          backgroundColor: lineColors.mobile,
        },
        {
          metric: 'cls',
          device: 'mobile',
          label: 'Mobile',
          data: result.map(m => m.data.devices.mobile.vitals.CLS.p_values[0]),
          borderColor: lineColors.mobile,
          backgroundColor: lineColors.mobile,
        },
        {
          metric: 'fid',
          device: 'mobile',
          label: 'Mobile',
          data: result.map(m => m.data.devices.mobile.vitals.FID.p_values[0]),
          borderColor: lineColors.mobile,
          backgroundColor: lineColors.mobile,
        },
        {
          metric: 'res',
          device: 'tablet',
          label: 'Tablet',
          data: result.map(m => m.data.devices.tablet.p_scores[0]),
          borderColor: lineColors.tablet,
          backgroundColor: lineColors.tablet,
        },
        {
          metric: 'fcp',
          device: 'tablet',
          label: 'Tablet',
          data: result.map(m => m.data.devices.tablet.vitals.FCP.p_values[0]/1000),
          borderColor: lineColors.tablet,
          backgroundColor: lineColors.tablet,
        },
        {
          metric: 'lcp',
          device: 'tablet',
          label: 'Tablet',
          data: result.map(m => m.data.devices.tablet.vitals.LCP.p_values[0]/1000),
          borderColor: lineColors.tablet,
          backgroundColor: lineColors.tablet,
        },
        {
          metric: 'cls',
          device: 'tablet',
          label: 'Tablet',
          data: result.map(m => m.data.devices.tablet.vitals.CLS.p_values[0]),
          borderColor: lineColors.tablet,
          backgroundColor: lineColors.tablet,
        },
        {
          metric: 'fid',
          device: 'tablet',
          label: 'Tablet',
          data: result.map(m => m.data.devices.tablet.vitals.FID.p_values[0]),
          borderColor: lineColors.tablet,
          backgroundColor: lineColors.tablet,
        },
      ],
    }

    setAllData(setup)
    const filtered = {
      labels: setup.labels,
      datasets: setup.datasets.filter(s => s.metric === metric)
    }
    setData(filtered)
    const deploymentsLimitDate = new Date().setDate(new Date().getDate()-(parseInt(limit)))
    let deployments = mock.vercelDeployments
    res = await fetch(`/api/get?vercelauth=true&url=${ encodeURIComponent(`https://vercel.com/api/v6/deployments?projectId=prj_geVQ6rMbFVBHsc1BRsuVx97choo1&teamId=team_grru4Jusir6dBzUqAkDScxYD&limit=500&target=production&since=${deploymentsLimitDate}`) }`)
    deployments = await res.json()
    const succeeded = deployments.deployments.filter(d => d.checksConclusion === 'succeeded')
    const labelledOptions = JSON.parse(JSON.stringify(chartOptions))
    succeeded.forEach(s => {
      const date = new Date(s.createdAt).toLocaleDateString('en-US')
      const annotation = {
        type: 'line',
        borderWidth: 1.5,
        borderColor: 'grey',
        borderDash: [10,10],
        label: {
          display: (ctx: any) => ctx.hovered,
          backgroundColor: 'gray',
          content: (ctx: any) => [s.meta.githubCommitMessage,'Branch: '+ s.meta.githubCommitRef, 'Deployed by: '+ s.creator.username],
          textAlign: 'start'
        },
        scaleID: 'x',
        value: date,
        enter(ctx: any) {
          ctx.hovered = true;
          ctx.chart.update();
        },
        leave(ctx: any) {
          ctx.hovered = false;
          ctx.chart.update();
        }
      }
      if(labels.includes(date)) {
        labelledOptions.plugins.annotation.annotations[s.createdAt] = annotation
      }
    })
    setOptions(labelledOptions)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData(duration)
    .then(() => {
      setIsLoading(false)
    })
    .catch(e => {
      setIsLoading(false)
      console.log(e)
    })
  }, []);

  const handleMetricChange = function (e: { target: {value: string} }) {
    const val = e?.target?.value
    setTitle(metricNames[val])
    setExplained(metricsExplained[val])
    setMetric(val)
    setData({
      labels: allData.labels,
      datasets: allData.datasets.filter(a => a.metric === e?.target?.value)
    })
  }

  const handleDurationChange = function (e: unknown, newValue: unknown) {
    const val = newValue as string
    setDuration(val)
    fetchData(val)
  }
  
  return (
      <Sheet sx={{display: {md:'block', lg:'grid'}, gridTemplateColumns: {md:'repeat(16, 1fr)'}, gap: 1}}>
        <Card variant='outlined' sx={{gridRow: '1', gridColumn: '1/4', boxShadow: 'md', mb:1}}>
          <FormControl>
            <FormLabel>Metric:</FormLabel>
            <RadioGroup
              defaultValue="fcp"
              name="controlled-radio-buttons-group"
              value={metric}
              onChange={handleMetricChange}
              sx={{ mb: 2, mt: 1 }}
            >
              <Radio value="res" color='neutral' label={metricNames.res} />
              <Radio value="fcp" color='neutral' label={metricNames.fcp} />
              <Radio value="lcp" color='neutral' label={metricNames.lcp} />
              <Radio value="cls" color='neutral' label={metricNames.cls} />
              <Radio value="fid" color='neutral' label={metricNames.fid} />
            </RadioGroup>
            <Alert color="neutral" variant="outlined" sx={{ display: 'block', mb:4, boxShadow:'md'}}>
              <Typography color='neutral' level="h6" fontSize="md" fontWeight="lg" sx={{mb:1}}>{title}</Typography>
              <Typography fontSize="sm" sx={{mb:2}}>{explained}</Typography>
              <img src={`${metric}.png`} alt={metric} style={{maxWidth:'100%', borderRadius:'5px'}}></img>
            </Alert>
            </FormControl>
            <FormLabel>Duration:</FormLabel>
            <FormControl>
            <Select value={duration} onChange={handleDurationChange} sx={{mt:1}} color='neutral'>
              {Object.entries(availableDurations).map(([k,v])=><Option value={k} key={k}>{v}</Option>)}
            </Select>
          </FormControl>
        </Card>
        <Card variant='outlined' sx={{ gridRow: '1', gridColumn: '4/17', boxShadow: 'md', mb:1 }}>
        <Typography sx={{ textAlign: 'center', mb:2, mt:1 }} level="h2" fontSize="lg">{title}</Typography>
          {
            isLoading 
            ?
            <div style={{textAlign: 'center', margin: '200px'}}>
              <CircularProgress color="neutral" variant="soft"/>
            </div>
            : 
            <>
              <Typography sx={{ textAlign: 'center', display:'flex', margin:'auto', color:'#717171', fontSize:'13px'}}> 
                <div style={{width:'15px', height:'15px',borderRadius:'15px', borderStyle:'dashed', borderColor:'grey', borderWidth:'2px', marginRight:'5px'}}></div>
                Deployments
              </Typography>
              <Line id="vercel-chart" options={options} data={data} />
            </>
          }
        </Card>
      </Sheet>
  )
}

export default Vercel